<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue'

import type { Swiper as SwiperInstance } from 'swiper/types'

import 'swiper/css'

defineProps<{
  slideButtonClass?: string
  spaceBetween?: number
  images: {
    alt: string
    url: string
  }[]
}>()
const currentSlideIndex = ref(0)

const swiper = ref<SwiperInstance | undefined>()

function onSlideButtonClick(index: number) {
  swiper.value?.slideTo(index)
}
</script>

<template>
  <div class="isolate max-w-container py-4">
    <Swiper
      centered-slides
      :slides-per-view="1"
      :breakpoints="{
        768: {
          slidesPerView: 2,
        },
      }"
      :space-between="spaceBetween"
      class="h-full"
      wrapper-class="flex items-end max-h-[50vh]"
      @swiper="(_swiper: SwiperInstance) => (swiper = _swiper)"
      @slide-change="(swiper: SwiperInstance) => (currentSlideIndex = swiper.realIndex)"
    >
      <SwiperSlide v-for="image in images" :key="image.url">
        <NuxtImg
          :src="image.url"
          :alt="image.alt"
          draggable="false"
          class="h-full max-h-[50vh] w-full select-none object-contain object-bottom"
        />
      </SwiperSlide>
    </Swiper>
    <div
      aria-hidden="true"
      class="relative z-10 mt-[calc(-1.2*var(--line-height))] flex h-24 items-end justify-center gap-4 ease-in-out [--line-height-selected:5rem] [--line-height:3.5rem] md:mt-[calc(-1*var(--line-height))] md:[--line-height-selected:6rem] md:[--line-height:4rem]"
    >
      <button
        v-for="key of images.length"
        :key="key"
        class="origin-bottom-left -translate-x-full rotate-[40deg] p-4"
        @click="() => onSlideButtonClick(key - 1)"
      >
        <div
          :class="[
            slideButtonClass || 'bg-primary',
            key - 1 === currentSlideIndex ? 'h-[--line-height-selected]' : 'h-[--line-height]',
          ]"
          class="w-[0.4rem] transition-all"
          :style="{
            clipPath: 'polygon(0 100%, 100% calc(100% - 0.335rem), 100% 0, 0 0.335rem)',
          }"
        />
      </button>
    </div>
  </div>
</template>
